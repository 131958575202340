import { Steps, Button, Space, Modal } from "antd";
import { FC, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  PlanVisibility,
  IntervalUnit,
  PlanCurrency,
  CreatePlanBody,
  PlanType,
  CreateRateBody,
  RateType,
  PlanTier,
  PlanRecurrenceTier,
} from "@omi-lab/cresus-typescript";
import { FormikProvider, useFormik } from "formik";
import qs from "query-string";

import { useClientsStore } from "../../../store/clients";

import { STEPS, steps } from "../utils/steps";
import {
  VIDEO_RESOLUTION_DURATION_PAIRS,
  PHOTO_RESOLUTION_DURATION_PAIRS,
} from "../utils/constants";
import { ValidationScheme } from "./Form.schema";
import { Details } from "./Details";
import { Usage } from "./Usage";
import { Rates } from "./Rates";
import { Summary } from "./Summary";

interface Props {}

export const Form: FC<Props> = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const plansClient = useClientsStore((store) => store.plansClient);

  const formik = useFormik({
    initialValues: {
      id: qs.parse(location.search)["id"] as string,
      name: "",
      type: PlanType.Tiered,
      currency: PlanCurrency.Eur,
      price: 0,
      isTrial: false,
      allowForExtraCredits: true,
      creditsUpTo: 0,
      extraCreditUnitAmountCents: 0,
      billingInterval: 1,
      billingIntervalUnit: IntervalUnit.Month,
      creditsUsageInterval: 1,
      creditsUsageIntervalUnit: IntervalUnit.Month,
      visibility: PlanVisibility.Public,
      trialDurationWeeks: 0,
      features: [] as string[],
      tier: PlanTier.Essentials,
      recurrenceTier: PlanRecurrenceTier.Monthly,
      rates: [
        ...PHOTO_RESOLUTION_DURATION_PAIRS,
        ...VIDEO_RESOLUTION_DURATION_PAIRS,
        {
          type: RateType.Modelling,
          duration: null,
          resolutionName: null,
          rate: 100,
        },
        {
          type: RateType.Assetsobject,
          duration: null,
          resolutionName: null,
          rate: 25,
        },
        {
          type: RateType.Assetsmaterial,
          duration: null,
          resolutionName: null,
          rate: 25,
        },
        {
          type: RateType.AssetsskyBox,
          duration: null,
          resolutionName: null,
          rate: 25,
        },
        {
          type: RateType.Onboardingfeedback,
          duration: null,
          resolutionName: null,
          rate: 25,
        },
        {
          type: RateType.ModellingupdateLabel,
          duration: null,
          resolutionName: null,
          rate: 25,
        },
      ].map(
        (item) =>
          ({
            duration: item.duration,
            rate: item.rate,
            resolutionName: item.resolutionName,
            type: item.type,
          }) as CreateRateBody,
      ),
      cancelAtPeriodEnd: false,
    } as CreatePlanBody,
    onSubmit: (values) => console.log(values),
    validateOnMount: false,
    validationSchema: ValidationScheme,
  });

  const createPlan = async () => {
    try {
      setIsLoading(true);

      await plansClient.createPlan({
        body: formik.values,
        returnRelatedPlanQuotas: true,
      });

      Modal.success({
        content: "Successfully created the plan",
        closable: true,
        okText: "Go to plans",
        onOk: () => history.push("/plans"),
      });
    } catch (error: any) {
      Modal.error({
        content: error?.response?.data?.error || error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderForm = (step: number) => {
    switch (step) {
      case STEPS.FILL_DETAILS:
        return <Details />;
      case STEPS.ADD_USAGE:
        return <Usage />;
      case STEPS.ADD_RATES:
        return <Rates />;
      case STEPS.SUMMARY:
        return <Summary />;
    }
  };

  const getNextButton = (step: number) => {
    switch (step) {
      case STEPS.SUMMARY:
        return (
          <Button
            type="primary"
            onClick={() => createPlan()}
            disabled={
              !Object.keys(formik.touched).length ||
              !!Object.keys(formik.errors).length ||
              isLoading
            }
            loading={isLoading}
          >
            Create
          </Button>
        );
      default:
        return (
          <Button
            type="primary"
            onClick={() => setCurrentStep(currentStep + 1)}
            disabled={
              !Object.keys(formik.touched).length ||
              !!Object.keys(formik.errors).length
            }
          >
            Next
          </Button>
        );
    }
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Steps current={currentStep}>
        {steps.map((step) => (
          <Steps.Step key={step.key} title={step.title} />
        ))}
      </Steps>
      <div className="steps-action">
        {currentStep > 0 && (
          <Button
            style={{ margin: "0 8px" }}
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            Previous
          </Button>
        )}
        {getNextButton(currentStep)}
      </div>
      <div className="steps-content">
        <FormikProvider value={formik}>
          {renderForm(currentStep)}
        </FormikProvider>
      </div>
    </Space>
  );
};
