import { useState, useEffect } from "react";
import { QuotaCoupon } from "@omi-lab/cresus-typescript";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";

export function useListQuotaCoupons(organizationId?: string) {
  const [quotaCoupons, setQuotaCoupons] = useState<QuotaCoupon[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [quotaCouponsClient] = useClientsStore((store) => [
    store.quotaCouponsClient,
  ]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (!organizationId) {
          return;
        }
        setQuotaCoupons(
          (
            await quotaCouponsClient.listQuotaCoupons({
              organizationId,
            })
          ).data,
        );
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [organizationId, quotaCouponsClient]);

  return { quotaCoupons, setQuotaCoupons, isLoading };
}
