import { RateType, ResolutionName } from "@omi-lab/cresus-typescript";

export const PHOTO_RESOLUTIONS = [
  ResolutionName._2k,
  ResolutionName._4k,
  ResolutionName._8k,
];
export const VIDEO_RESOLUTIONS = [
  ResolutionName.Hd,
  ResolutionName._2k,
  ResolutionName._4k,
  ResolutionName._8k,
];
export const VIDEO_DURATIONS = [2, 4, 6, 8];

export const VIDEO_RESOLUTION_DURATION_PAIRS = [
  {
    resolutionName: ResolutionName.Hd,
    duration: 2,
    type: RateType.Video,
    rate: 20,
  },
  {
    resolutionName: ResolutionName._2k,
    duration: 2,
    type: RateType.Video,
    rate: 40,
  },
  {
    resolutionName: ResolutionName.Hd,
    duration: 4,
    type: RateType.Video,
    rate: 40,
  },
  {
    resolutionName: ResolutionName._2k,
    duration: 4,
    type: RateType.Video,
    rate: 80,
  },
  {
    resolutionName: ResolutionName.Hd,
    duration: 6,
    type: RateType.Video,
    rate: 60,
  },
  {
    resolutionName: ResolutionName._2k,
    duration: 6,
    type: RateType.Video,
    rate: 120,
  },
  {
    resolutionName: ResolutionName.Hd,
    duration: 8,
    type: RateType.Video,
    rate: 80,
  },
  {
    resolutionName: ResolutionName._2k,
    duration: 8,
    type: RateType.Video,
    rate: 160,
  },
];

export const PHOTO_RESOLUTION_DURATION_PAIRS = [
  {
    resolutionName: ResolutionName._2k,
    duration: undefined,
    type: RateType.Photo,
    rate: 10,
  },
  {
    resolutionName: ResolutionName._4k,
    duration: undefined,
    type: RateType.Photo,
    rate: 40,
  },
  {
    resolutionName: ResolutionName._8k,
    duration: undefined,
    type: RateType.Photo,
    rate: 80,
  },
];

export const RateTypeToHuman: Record<RateType, string> = {
  [RateType.Photo]: "Product Photo",
  [RateType.Video]: "Product Video",
  [RateType.Assetsobject]: "Premium Object",
  [RateType.Assetsmaterial]: "Premium Material",
  [RateType.AssetsskyBox]: "Premium Sky Box",
  [RateType.Modelling]: "Modelling",
  [RateType.Onboardingfeedback]: "Onboarding Feedback",
  [RateType.ModellingupdateLabel]: "Update Label",
  [RateType._360sview]: "360s View",
};
