import {
  notification,
  Modal,
  Table,
  Input,
  Form,
  Select,
  Button,
  Space,
} from "antd";
import { FC, useState } from "react";
import { format } from "date-fns";
import { PlanQuotaName } from "@omi-lab/cresus-typescript";
import * as Yup from "yup";

import { useClientsStore } from "../../../store/clients";
import { useFormik } from "formik";

import { useListQuotaCoupons } from "../hooks/useListQuotaCoupons";

interface Props {
  organizationId: string;
  close: () => void;
}

export const OrganizationManageQuotaCoupons: FC<Props> = (props) => {
  const {
    quotaCoupons,
    setQuotaCoupons,
    isLoading: isLoadingQuotaCoupons,
  } = useListQuotaCoupons(props.organizationId);

  const [isLoading, setIsLoading] = useState(false);

  const [quotaCouponsClient] = useClientsStore((store) => [
    store.quotaCouponsClient,
  ]);

  const { values, setValues, handleSubmit, isValid } = useFormik({
    initialValues: {} as {
      quotaName: PlanQuotaName;
      amount: number;
    },
    validationSchema: Yup.object({
      quotaName: Yup.string().oneOf(Object.values(PlanQuotaName)).required(),
      amount: Yup.number().min(0).required(),
    }),
    validateOnMount: true,
    onSubmit: (values) => createQuotaCoupon(values),
  });

  const createQuotaCoupon = async (params: {
    quotaName: PlanQuotaName;
    amount: number;
  }) => {
    try {
      setIsLoading(true);

      const quotaCoupon = await quotaCouponsClient
        .createQuotaCoupon({
          body: {
            organizationId: props.organizationId,
            quotaName: params.quotaName,
            amount: params.amount,
          },
        })
        .then((response) => response.data);

      notification.success({
        message: "Successfully created the quota coupon.",
      });

      setQuotaCoupons([...quotaCoupons, quotaCoupon]);
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteQuotaCoupon = async (quotaCouponId: string) => {
    try {
      setIsLoading(true);

      await quotaCouponsClient
        .deleteQuotaCoupon({
          quotaCouponId: quotaCouponId,
        })
        .then((response) => response.data);

      notification.success({
        message: "Successfully upgraded the subscription.",
      });

      setQuotaCoupons(quotaCoupons.filter(({ id }) => id !== quotaCouponId));
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={true}
      onOk={props.close}
      onCancel={props.close}
      okButtonProps={{
        loading: isLoadingQuotaCoupons || isLoading,
      }}
      style={{
        minWidth: "700px",
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Table
          dataSource={quotaCoupons}
          columns={[
            {
              title: "Quota",
              dataIndex: "quotaName",
              key: "quotaName",
            },
            {
              title: "Amount",
              dataIndex: "amount",
              key: "amount",
            },
            {
              title: "Created at",
              dataIndex: "createdAt",
              key: "createdAt",
              render: (value) => format(new Date(value), "yyyy-MM-dd hh:mm"),
            },
            {
              title: "Delete",
              dataIndex: "",
              key: "",
              render: (_, record) => (
                <Button danger onClick={() => deleteQuotaCoupon(record.id)}>
                  -
                </Button>
              ),
            },
          ]}
        />
        <Form initialValues={values} layout="inline">
          <Form.Item name="quotaName" label="Quota">
            <Select
              placeholder="Please pick a quota"
              onChange={(value) =>
                setValues((values) => ({
                  ...values,
                  quotaName: value as PlanQuotaName,
                }))
              }
            >
              {Object.values(PlanQuotaName).map((name) => (
                <Select.Option value={name}>{name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Amount" name="amount">
            <Input
              type="number"
              onChange={(event) =>
                setValues((values) => ({
                  ...values,
                  amount: Number.parseInt(event.target.value),
                }))
              }
            />
          </Form.Item>
          <Button
            disabled={!isValid}
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            Create
          </Button>
        </Form>
      </Space>
    </Modal>
  );
};
