import { FC } from "react";
import { Form, Col, Tabs } from "antd";

import { Quotas } from "./Quotas";
import { CreatePlanBody, PlanType } from "@omi-lab/cresus-typescript";
import { useFormikContext } from "formik";

interface Props {}

export const Usage: FC<Props> = () => {
  const { values } = useFormikContext<CreatePlanBody>();

  return (
    <Col
      style={{
        backgroundColor: "#fafafa",
        border: "1px dashed #d9d9d9",
        alignItems: "center",
        padding: "30px",
      }}
    >
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
        <Tabs defaultActiveKey="quota">
          <Tabs.TabPane tab="Quota" key="quota">
            {values.type === PlanType.Simple && <Quotas />}
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Col>
  );
};
