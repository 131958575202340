import {
  notification,
  Modal,
  Table,
  Input,
  Form,
  Button,
  Space,
  DatePicker,
} from "antd";
import { FC, useState } from "react";
import { format } from "date-fns";
import * as Yup from "yup";

import { useClientsStore } from "../../../store/clients";
import { useFormik } from "formik";

import { useListOrganizationCreditsCoupons } from "../hooks/useListOrganizationCreditsCoupons";

interface Props {
  organizationId: string;
  close: () => void;
}

export const OrganizationManageOrganizationCreditsCoupons: FC<Props> = (
  props,
) => {
  const {
    organizationCreditsCoupons,
    setOrganizationCreditsCoupons,
    isLoading: isLoadingOrganizationCreditsCoupons,
  } = useListOrganizationCreditsCoupons(props.organizationId);

  const [isLoading, setIsLoading] = useState(false);

  const [organizationCreditsCouponsClient] = useClientsStore((store) => [
    store.organizationCreditsCouponsClient,
  ]);

  const { values, setValues, handleSubmit, isValid } = useFormik({
    initialValues: {} as {
      amount: number;
      expiresAt?: Date;
    },
    validationSchema: Yup.object({
      amount: Yup.number().min(0).required(),
      expiresAt: Yup.date().optional().nullable(),
    }),
    validateOnMount: true,
    onSubmit: (values) => createOrganizationCreditsCoupon(values),
  });

  const createOrganizationCreditsCoupon = async (params: {
    amount: number;
    expiresAt?: Date;
  }) => {
    try {
      setIsLoading(true);

      const coupon = await organizationCreditsCouponsClient
        .createOrganizationCreditsCoupon({
          body: {
            organizationId: props.organizationId,
            amount: params.amount,
            expiresAt: params.expiresAt?.toISOString(),
          },
        })
        .then((response) => response.data);

      notification.success({
        message: "Successfully created the quota coupon.",
      });

      setOrganizationCreditsCoupons([...organizationCreditsCoupons, coupon]);
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={true}
      onOk={props.close}
      onCancel={props.close}
      okButtonProps={{
        loading: isLoadingOrganizationCreditsCoupons || isLoading,
      }}
      style={{
        minWidth: "700px",
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Table
          dataSource={organizationCreditsCoupons}
          columns={[
            {
              title: "Amount",
              dataIndex: "amount",
              key: "amount",
              width: "20%",
            },
            {
              title: "Used",
              dataIndex: "used",
              key: "used",
              width: "20%",
            },
            {
              title: "Issued by",
              dataIndex: "issuedBy",
              key: "issuedBy",
              width: "20%",
            },
            {
              title: "Created at",
              dataIndex: "createdAt",
              key: "createdAt",
              render: (value) => format(new Date(value), "yyyy-MM-dd hh:mm"),
              width: "20%",
            },
            {
              title: "Expires at",
              dataIndex: "expiresAt",
              key: "expiresAt",
              render: (value) => format(new Date(value), "yyyy-MM-dd hh:mm"),
              width: "20%",
            },
          ]}
        />
        <Form initialValues={values} layout="inline">
          <Form.Item label="Amount" name="amount">
            <Input
              type="number"
              onChange={(event) =>
                setValues((values) => ({
                  ...values,
                  amount: Number.parseInt(event.target.value),
                }))
              }
            />
          </Form.Item>
          <Form.Item label="Expires at" name="expiresAt">
            <DatePicker
              onChange={(date) =>
                setValues((values) => ({
                  ...values,
                  expiresAt: date?.toDate(),
                }))
              }
            />
          </Form.Item>
          <Button
            disabled={!isValid}
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            Create
          </Button>
        </Form>
      </Space>
    </Modal>
  );
};
