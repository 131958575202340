import { FC, useState } from "react";
import { Form, Input, Modal, Select } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  CreateRateDiscountPolicyBody,
  RateDiscountPolicyType,
  RateType,
} from "@omi-lab/cresus-typescript";

import { useListPlans } from "../hooks";

interface Props {
  isVisible: boolean;
  setIsVisible: (visible: boolean) => void;
  createRateDiscountPolicy: (
    body: CreateRateDiscountPolicyBody,
  ) => Promise<void>;
}

export const CreateRateDiscountPolicyModal: FC<Props> = ({
  isVisible,
  setIsVisible,
  createRateDiscountPolicy,
}) => {
  const [nameIncludes, setNameIncludes] = useState<string>();

  const { plans } = useListPlans({
    page: 1,
    pageSize: 15,
    nameIncludes,
  });

  const { values, setValues, handleChange, handleBlur, handleSubmit, isValid } =
    useFormik({
      initialValues: {
        policyType: RateDiscountPolicyType.Volume,
        rateType: RateType.Modelling,
        minUnits: 0,
        discountPercents: 0,
        planIds: [],
      } as CreateRateDiscountPolicyBody,
      validationSchema: Yup.object({
        policyType: Yup.string()
          .oneOf(Object.values(RateDiscountPolicyType))
          .required(),
        rateType: Yup.string().oneOf(Object.values(RateType)).required(),
        minUnits: Yup.number().min(0).required(),
        discountPercents: Yup.number().min(0).required(),
        planIds: Yup.array().of(Yup.string().required()).required(),
      }).required(),
      onSubmit: async (values, { resetForm }) => {
        await createRateDiscountPolicy(values);
        resetForm();
        setIsVisible(false);
      },
    });

  return (
    <Modal
      title="Create a rate discount policy"
      open={isVisible}
      onOk={() => handleSubmit()}
      okButtonProps={{ disabled: !isValid }}
      onCancel={() => setIsVisible(false)}
    >
      <Form.Item label="Policy type">
        <Select
          value={values.policyType}
          onSelect={(value) =>
            setValues((values) => ({ ...values, policyType: value }))
          }
        >
          {Object.values(RateDiscountPolicyType).map((item, index) => (
            <Select.Option key={index} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Rate type">
        <Select
          value={values.rateType}
          onSelect={(value) =>
            setValues((values) => ({ ...values, rateType: value }))
          }
        >
          {Object.values(RateType).map((item, index) => (
            <Select.Option key={index} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Min units">
        <Input
          placeholder="Min units"
          name="minUnits"
          type="number"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.minUnits}
        />
      </Form.Item>
      <Form.Item label="Discount percents">
        <Input
          placeholder="Discount percents"
          name="discountPercents"
          type="number"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.discountPercents}
        />
      </Form.Item>
      <Form.Item label="Plans">
        <Select
          allowClear
          showSearch
          placeholder="Plans"
          filterOption={false}
          mode="multiple"
          value={values.planIds}
          onSearch={(value) => setNameIncludes(value)}
          onSelect={(value) =>
            setValues((values) => ({
              ...values,
              planIds: [...values.planIds, value],
            }))
          }
          onDeselect={(value) =>
            setValues((values) => ({
              ...values,
              planIds: values.planIds.filter((str) => str !== value),
            }))
          }
          onClear={() => setValues((values) => ({ ...values, planIds: [] }))}
        >
          {plans.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Modal>
  );
};
