import { Col } from "antd";
import { FC } from "react";
import { RouteComponentProps } from "react-router-dom";

import { Form } from "../components/Form";

interface Props extends RouteComponentProps {}

export const PlanCreate: FC<Props> = (props) => {
  return (
    <Col style={{ padding: "15px" }}>
      <Form {...props} />
    </Col>
  );
};
