import { FC } from "react";
import { Input, Table } from "antd";
import { useFormikContext } from "formik";
import { CreatePlanBody, RateType } from "@omi-lab/cresus-typescript";
import { RateTypeToHuman } from "../utils/constants";

interface Props {}

export const Rates: FC<Props> = () => {
  const { values, handleChange, handleBlur } =
    useFormikContext<CreatePlanBody>();

  return (
    <Table
      dataSource={values.rates}
      columns={[
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          width: "25%",
          render: (type: RateType) => <p>{RateTypeToHuman[type]}</p>,
        },
        {
          title: "Resolution",
          dataIndex: "resolutionName",
          key: "resolutionName",
          width: "25%",
        },
        {
          title: "Duration",
          dataIndex: "duration",
          key: "duration",
          width: "25%",
        },
        {
          title: "Rate",
          dataIndex: "rate",
          key: "rate",
          width: "25%",
          render: (rate, _, index) => (
            <Input
              type="number"
              defaultValue={rate}
              value={rate}
              onChange={handleChange}
              onBlur={handleBlur}
              name={`rates.${index}.rate`}
            />
          ),
        },
      ]}
      pagination={{ pageSize: 100 }}
    />
  );
};
