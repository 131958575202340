import { FC } from "react";
import { Form, Input, Col, Select, Divider, Button } from "antd";
import has from "lodash.has";
import { ErrorMessage, useFormikContext } from "formik";
import {
  CreatePlanBody,
  IntervalUnit,
  PlanQuotaName,
} from "@omi-lab/cresus-typescript";

interface Props {}

export const Quotas: FC<Props> = () => {
  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    setValues,
    setFieldValue,
  } = useFormikContext<CreatePlanBody>();

  return (
    <Col
      style={{
        backgroundColor: "#fafafa",
        border: "1px dashed #d9d9d9",
        alignItems: "center",
        padding: "30px",
      }}
    >
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
        {(values.quotas || []).map((quota, index) => (
          <>
            <Form.Item
              label="Quota"
              name={`quotas.${index}.name`}
              help={<ErrorMessage name={`quotas.${index}.name`} />}
              validateStatus={
                touched.quotas &&
                errors.quotas &&
                errors.quotas[index] &&
                has(errors, `quotas.${index}.name`)
                  ? "error"
                  : "success"
              }
            >
              <Select
                defaultValue={quota.name}
                value={quota.name}
                onChange={(
                  (_: number) => (name: string) =>
                    setFieldValue(`quotas.${index}.name`, name)
                )(index)}
                onBlur={handleBlur}
              >
                {Object.values(PlanQuotaName).map((type) => (
                  <Select.Option value={type}>{type}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Max usage"
              name={`quotas.${index}.maxUsage`}
              help={<ErrorMessage name={`quotas.${index}.maxUsage`} />}
              validateStatus={
                touched.quotas &&
                errors.quotas &&
                errors.quotas[index] &&
                has(errors, `quotas.${index}.maxUsage`)
                  ? "error"
                  : "success"
              }
            >
              <Input
                type="number"
                defaultValue={quota.maxUsage}
                value={quota.maxUsage}
                onChange={handleChange}
                onBlur={handleBlur}
                name={`quotas.${index}.maxUsage`}
              />
            </Form.Item>
            <Form.Item
              label="Interval unit"
              name={`quotas.${index}.intervalUnit`}
              help={<ErrorMessage name={`quotas.${index}.intervalUnit`} />}
              validateStatus={
                touched.quotas &&
                errors.quotas &&
                errors.quotas[index] &&
                has(errors, `quotas.${index}.intervalUnit`)
                  ? "error"
                  : "success"
              }
            >
              <Select
                defaultValue={quota.intervalUnit}
                value={quota.intervalUnit}
                allowClear={true}
                onClear={() =>
                  setFieldValue(`quotas.${index}.intervalUnit`, undefined)
                }
                onChange={(
                  (_: number) => (intervalUnit: string) =>
                    setFieldValue(`quotas.${index}.intervalUnit`, intervalUnit)
                )(index)}
                onBlur={handleBlur}
              >
                {Object.values(IntervalUnit).map((unit) => (
                  <Select.Option value={unit}>{unit}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Interval"
              name={`quotas.${index}.interval`}
              help={<ErrorMessage name={`quotas.${index}.interval`} />}
              validateStatus={
                touched.quotas &&
                errors.quotas &&
                errors.quotas[index] &&
                has(errors, `quotas.${index}.interval`)
                  ? "error"
                  : "success"
              }
            >
              <Input
                type="number"
                defaultValue={quota.interval}
                allowClear={true}
                value={quota.interval}
                onChange={handleChange}
                onBlur={handleBlur}
                name={`quotas.${index}.interval`}
              />
            </Form.Item>
            {index !== (values.quotas?.length || 0) - 1 && <Divider />}
          </>
        ))}
        <Button
          onClick={() =>
            setValues((values) => ({
              ...values,
              quotas: [
                ...(values.quotas || []),
                {
                  name: PlanQuotaName.ProductPhotos,
                },
              ],
            }))
          }
        >
          Add a new quota
        </Button>
      </Form>
    </Col>
  );
};
