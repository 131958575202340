import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  List,
  Menu,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
  notification,
} from "antd";
import { FC, useState } from "react";
import {
  PlanType,
  Subscription,
  SubscriptionStatus,
} from "@omi-lab/cresus-typescript";

import { useClientsStore } from "../../../store/clients";

import { OrganizationSubscriptionEdit } from "./OrganizationSubscriptionEdit";
import { useListSubscriptions } from "../hooks/useListSubscriptions";
import { format } from "date-fns";
import { OrganizationManageQuotaCoupons } from "./OrganizationManageQuotaCoupons";
import { OrganizationManageOrganizationCreditsCoupons } from "./OrganizationManageOrganizationCreditsCoupons";

interface Props {
  organizationId: string;
}

export const OrganizationSubscriptionsDetails: FC<Props> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isQuotaCouponsModalVisible, setIsQuotaCouponsModalVisible] =
    useState(false);
  const [isCancellingSubscription, setIsCancellingSubscription] =
    useState(false);
  const [isCancellingScheduledUpdate, setIsCancellingScheduledUpdate] =
    useState(false);
  const [currentSubscription, setCurrentSubscription] =
    useState<Subscription>();
  const [isActive, setIsActive] = useState<boolean | undefined>(true);

  const { subscriptions, setSubscriptions, isLoading } = useListSubscriptions({
    organizationId: props.organizationId,
    isActive,
  });

  const [subscriptionsClient, subscriptionScheduledUpdatesClient] =
    useClientsStore((store) => [
      store.subscriptionsClient,
      store.subscriptionScheduledUpdatesClient,
    ]);

  const cancelSubscription = async (id: string, force?: boolean) => {
    try {
      setIsCancellingSubscription(true);

      const { data } = await subscriptionsClient.cancelSubscription({
        subscriptionId: id,
        force,
      });

      setSubscriptions((subscriptions) =>
        subscriptions
          .map((subscription) =>
            subscription.id === data.id ? data : subscription,
          )
          .filter(
            ({ status }) =>
              !isActive ||
              (
                [
                  SubscriptionStatus.Active,
                  SubscriptionStatus.Incomplete,
                  SubscriptionStatus.PastDue,
                ] as SubscriptionStatus[]
              ).includes(status!),
          ),
      );

      notification.success({
        message: "Successfully cancelled the subscription.",
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
      });
    } finally {
      setIsCancellingSubscription(false);
    }
  };

  const cancelScheduledUpdate = async (subscription: Subscription) => {
    try {
      setIsCancellingScheduledUpdate(true);

      if (!subscription.scheduledUpdate?.id) {
        return;
      }

      await subscriptionScheduledUpdatesClient.deleteSubscriptionScheduledUpdate(
        { subscriptionScheduledUpdateId: subscription.scheduledUpdate.id },
      );

      setSubscriptions((subscriptions) =>
        subscriptions.map((s) =>
          s.id === subscription.id
            ? { ...subscription, scheduledUpdate: undefined }
            : s,
        ),
      );

      notification.success({
        message: "Successfully cancelled the subscription scheduled update.",
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
      });
    } finally {
      setIsCancellingScheduledUpdate(false);
    }
  };

  const updateSubscriptionStatus = async (
    id: string,
    status: SubscriptionStatus,
  ) => {
    try {
      const { data } = await subscriptionsClient.updateSubscriptionStatus({
        subscriptionId: id,
        body: {
          status,
        },
      });

      setSubscriptions(
        subscriptions.map((subscription) =>
          subscription.id === id ? data : subscription,
        ),
      );

      notification.success({
        message: "Successfully updated the status.",
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
      });
    }
  };

  return (
    <>
      <Col style={{ padding: "15px" }}>
        <List grid={{ gutter: 16 }}>
          <List.Item>
            <Row gutter={16} justify="space-between">
              <Col span={4}>
                {!(subscriptions || []).find(
                  ({ endsAt }) => !endsAt || new Date(endsAt) > new Date(),
                ) &&
                  !isLoading && (
                    <Button
                      type="primary"
                      onClick={() => setIsModalVisible(true)}
                    >
                      Subscribe now
                    </Button>
                  )}
              </Col>
              <Col span={4}>
                <Checkbox
                  checked={isActive}
                  onChange={(e) =>
                    setIsActive(e.target.checked === true ? true : undefined)
                  }
                >
                  Active only
                </Checkbox>
              </Col>
            </Row>
          </List.Item>
          <List.Item>
            <Table
              dataSource={subscriptions}
              loading={isLoading}
              columns={[
                {
                  title: "Plan",
                  key: "plan.name",
                  render: (_, subscription) => (
                    <p>
                      {subscription.plan?.name ||
                        subscription.productInstance?.product?.name}
                    </p>
                  ),
                  width: "10%",
                },
                {
                  title: "Type",
                  key: "type",
                  dataIndex: "type",
                  width: "5%",
                },
                {
                  title: "Status",
                  key: "status",
                  dataIndex: "status",
                  width: "10%",
                  render: (_, subscription: Subscription) => (
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Status"
                      defaultValue={subscription.status}
                      onSelect={(value: SubscriptionStatus) =>
                        updateSubscriptionStatus(subscription.id, value)
                      }
                      disabled={(
                        [
                          SubscriptionStatus.Canceled,
                          SubscriptionStatus.IncompleteExpired,
                          SubscriptionStatus.Active,
                        ] as Array<SubscriptionStatus>
                      ).includes(subscription.status!)}
                    >
                      {[SubscriptionStatus.Active].map((status) => (
                        <Select.Option value={status}>{status}</Select.Option>
                      ))}
                    </Select>
                  ),
                },
                {
                  title: "Billing period start",
                  key: "currentPeriodStart",
                  render: (_, subscription: Subscription) => (
                    <p>
                      {format(
                        new Date(subscription.currentPeriodStart),
                        "yyyy-MM-dd HH:mm:ss",
                      )}
                    </p>
                  ),
                  width: "10%",
                },
                {
                  title: "Billing period end",
                  key: "currentPeriodEnd",
                  render: (_, subscription: Subscription) => (
                    <p>
                      {format(
                        new Date(subscription.currentPeriodEnd),
                        "yyyy-MM-dd HH:mm:ss",
                      )}
                    </p>
                  ),
                  width: "10%",
                },
                {
                  title: "Credits period start",
                  key: "creditsPeriodStart",
                  render: (_, subscription: Subscription) => (
                    <p>
                      {subscription.type === PlanType.Tiered
                        ? format(
                            new Date(
                              subscription.creditUsageSummary
                                ?.currentPeriodStart!,
                            ),
                            "yyyy-MM-dd HH:mm:ss",
                          )
                        : "N/A"}
                    </p>
                  ),
                  width: "10%",
                },
                {
                  title: "Credits period end",
                  key: "creditsPeriodEnd",
                  render: (_, subscription: Subscription) => (
                    <p>
                      {subscription.type === PlanType.Tiered
                        ? format(
                            new Date(
                              subscription.creditUsageSummary
                                ?.currentPeriodEnd!,
                            ),
                            "yyyy-MM-dd HH:mm:ss",
                          )
                        : "N/A"}
                    </p>
                  ),
                  width: "10%",
                },
                {
                  title: "Quota usages",
                  key: "quotaUsages",
                  render: (_, subscription: Subscription) => (
                    <>
                      {subscription.type === PlanType.Simple ? (
                        subscription.quotaUsages?.map((quotaUsage) => (
                          <Tag color="success">
                            {quotaUsage.planQuotaName}:{" "}
                            {`${quotaUsage.issuedTokens}/${
                              quotaUsage.maxTokens
                            } + ${quotaUsage.credits || 0} credits`}
                          </Tag>
                        ))
                      ) : (
                        <Tag color="success">
                          {subscription.creditUsageSummary?.creditsUsed}/
                          {subscription.creditUsageSummary?.maxCredits}
                        </Tag>
                      )}
                    </>
                  ),
                  width: "5%",
                },
                {
                  title: "Canceled at",
                  key: "canceledAt",
                  render: (_, subscription: Subscription) => (
                    <p>
                      {subscription.canceledAt
                        ? format(
                            new Date(subscription.canceledAt),
                            "yyyy-MM-dd",
                          )
                        : "N/A"}
                    </p>
                  ),
                  width: "5%",
                },
                {
                  title: "Ends at",
                  key: "endsAt",
                  render: (_, subscription: Subscription) => (
                    <p>
                      {subscription.endsAt
                        ? format(new Date(subscription.endsAt), "yyyy-MM-dd")
                        : "N/A"}
                    </p>
                  ),
                  width: "5%",
                },
                {
                  title: "Has scheduled update",
                  key: "hasScheduledUpdate",
                  render: (_, subscription: Subscription) => (
                    <Checkbox
                      checked={!!subscription.scheduledUpdate}
                      disabled
                    />
                  ),
                  width: "5%",
                },
                {
                  title: "Action",
                  key: "action",
                  render: (_, subscription: Subscription) => (
                    <Space>
                      {subscription.status === SubscriptionStatus.Active && (
                        <Button
                          type="primary"
                          onClick={() => {
                            setCurrentSubscription(subscription);
                            setIsModalVisible(true);
                          }}
                        >
                          Edit
                        </Button>
                      )}
                      {(subscription.status === SubscriptionStatus.Active ||
                        new Date(subscription.endsAt!) > new Date()) && (
                        <Button
                          type="dashed"
                          onClick={() => {
                            setCurrentSubscription(subscription);
                            setIsQuotaCouponsModalVisible(true);
                          }}
                        >
                          Coupons
                        </Button>
                      )}
                      {(subscription.status === SubscriptionStatus.Active ||
                        new Date(subscription.endsAt!) > new Date()) && (
                        <Popconfirm
                          title="Are you sure you want to cancel this subscription ?"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() =>
                            cancelSubscription(subscription.id, false)
                          }
                          okButtonProps={{
                            loading:
                              isCancellingSubscription ||
                              isCancellingScheduledUpdate,
                          }}
                        >
                          <Dropdown.Button
                            type="primary"
                            disabled={isCancellingSubscription}
                            overlay={
                              <Menu>
                                <Menu.Item
                                  disabled={isCancellingSubscription}
                                  onClick={() =>
                                    cancelSubscription(subscription.id, true)
                                  }
                                >
                                  Force cancel
                                </Menu.Item>
                                {subscription.scheduledUpdate && (
                                  <Menu.Item
                                    disabled={isCancellingScheduledUpdate}
                                    onClick={() =>
                                      cancelScheduledUpdate(subscription)
                                    }
                                  >
                                    Cancel scheduled update
                                  </Menu.Item>
                                )}
                              </Menu>
                            }
                          >
                            Cancel
                          </Dropdown.Button>
                        </Popconfirm>
                      )}
                    </Space>
                  ),
                  width: "15%",
                },
              ]}
            />
          </List.Item>
        </List>
      </Col>
      {isModalVisible && (
        <OrganizationSubscriptionEdit
          organizationId={props.organizationId}
          subscription={currentSubscription}
          close={(subscription) => {
            setIsModalVisible(false);

            if (subscription && currentSubscription) {
              setSubscriptions(
                subscriptions.map((s) =>
                  s.id === subscription.id ? subscription : s,
                ),
              );
            }
            if (subscription && !currentSubscription) {
              setSubscriptions([...subscriptions, subscription]);
            }

            setCurrentSubscription(undefined);
          }}
        />
      )}
      {isQuotaCouponsModalVisible &&
        currentSubscription &&
        currentSubscription?.type === PlanType.Simple && (
          <OrganizationManageQuotaCoupons
            organizationId={currentSubscription?.organizationId}
            close={() => setIsQuotaCouponsModalVisible(false)}
          />
        )}
      {isQuotaCouponsModalVisible &&
        currentSubscription &&
        currentSubscription?.type === PlanType.Tiered && (
          <OrganizationManageOrganizationCreditsCoupons
            organizationId={currentSubscription?.organizationId}
            close={() => setIsQuotaCouponsModalVisible(false)}
          />
        )}
    </>
  );
};
