export enum STEPS {
  FILL_DETAILS = 0,
  ADD_USAGE = 1,
  ADD_RATES = 2,
  SUMMARY = 3,
}

export const steps = [
  {
    key: STEPS.FILL_DETAILS,
    title: "Fill in the details",
  },
  {
    key: STEPS.ADD_USAGE,
    title: "Add usage",
  },
  {
    key: STEPS.ADD_RATES,
    title: "Add rates",
  },
  {
    key: STEPS.SUMMARY,
    title: "Summary",
  },
];
