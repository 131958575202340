import { useState, useEffect } from "react";
import {
  OrderByOrder,
  Plan,
  PlansOrderBy,
  PlanVisibility,
} from "@omi-lab/cresus-typescript";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";

export function useListPlans(params: {
  nameIncludes?: string;
  visibility?: PlanVisibility[];
  isTrial?: boolean;
  order?: OrderByOrder;
  orderBy?: PlansOrderBy;
  page?: number;
  pageSize?: number;
  returnRelatedFeatures?: boolean;
  returnRelatedPlanQuotas?: boolean;
  returnRelatedRates?: boolean;
}) {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);

  const [plansClient] = useClientsStore((store) => [store.plansClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await plansClient.listPlans({
          visibility: params.visibility,
          isTrial: params.isTrial,
          nameIncludes: params.nameIncludes,
          order: params.order,
          orderBy: params.orderBy,
          page: params.page,
          pageSize: params.pageSize,
          returnRelatedFeatures: params.returnRelatedFeatures,
          returnRelatedPlanQuotas: params.returnRelatedPlanQuotas,
          returnRelatedRates: params.returnRelatedRates,
        });

        setPlans(data);

        if (data.length === params.pageSize) {
          setPageCount((params.page || 0) + 1);
        }
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    plansClient,
    params.nameIncludes,
    params.visibility,
    params.isTrial,
    params.order,
    params.orderBy,
    params.page,
    params.pageSize,
    params.returnRelatedFeatures,
    params.returnRelatedPlanQuotas,
    params.returnRelatedRates,
  ]);

  return { plans, setPlans, isLoading, pageCount };
}
