import * as Yup from "yup";
import {
  PlanCurrency,
  PlanVisibility,
  IntervalUnit,
  PlanQuotaName,
  PlanType,
  RateType,
} from "@omi-lab/cresus-typescript";

export const ValidationScheme = Yup.object().shape({
  id: Yup.string().uuid().optional().nullable(),
  name: Yup.string()
    .min(1, "Name should be at least 1 characters long")
    .required("A name is required"),
  type: Yup.string()
    .oneOf(Object.values(PlanType))
    .required("A type is required"),
  currency: Yup.string()
    .oneOf(Object.values(PlanCurrency))
    .required("A currency is required"),
  price: Yup.number().min(0, "Price should be at least 0").required(),
  isTrial: Yup.boolean().required(),
  allowForExtraCredits: Yup.boolean()
    .when("isTrial", {
      is: (isTrial: boolean) => isTrial,
      then: Yup.boolean().isFalse(),
    })
    .required(),
  creditsUpTo: Yup.number().optional().nullable(),
  extraCreditUnitAmountCents: Yup.number().optional().nullable(),
  billingInterval: Yup.number()
    .min(1, "The billing interval should be greater than 0")
    .required("A billing interval is required"),
  billingIntervalUnit: Yup.string()
    .oneOf(Object.values(IntervalUnit))
    .required("A billing interval unit is required"),
  creditsUsageInterval: Yup.number()
    .min(1, "The credits uasge interval should be greater than 0")
    .required("A credits uasge interval is required"),
  creditsUsageIntervalUnit: Yup.string()
    .oneOf(Object.values(IntervalUnit))
    .required("A credits uasge interval unit is required"),
  visibility: Yup.string()
    .oneOf(Object.values(PlanVisibility))
    .required("A visibility is required"),
  trialDurationWeeks: Yup.number().min(0).optional().nullable(),
  cancelAtPeriodEnd: Yup.boolean()
    .when("isTrial", {
      is: (isTrial: boolean) => isTrial,
      then: Yup.boolean().isTrue(),
    })
    .required(),
  quotas: Yup.array(
    Yup.object({
      name: Yup.string()
        .oneOf(Object.values(PlanQuotaName))
        .required("A name is required"),
      maxUsage: Yup.number().required("A max usage is required"),
      interval: Yup.number()
        .min(0, "The interval should be greater than 0")
        .optional()
        .nullable(),
      intervalUnit: Yup.string()
        .oneOf(Object.values(IntervalUnit))
        .optional()
        .nullable(),
    }).required(),
  )
    .optional()
    .nullable(),
  rates: Yup.array(
    Yup.object({
      type: Yup.string()
        .oneOf(Object.values(RateType))
        .required("A type is required"),
      duration: Yup.number().optional().nullable(),
      resolutionName: Yup.string().optional().nullable(),
      rate: Yup.number()
        .min(0, "The rate should be greater than 0")
        .required("A rate is required"),
    }).required(),
  )
    .optional()
    .nullable(),
});
