import { useState, useEffect } from "react";
import { RateDiscountPolicy, RateType } from "@omi-lab/cresus-typescript";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";

export function useListRateDiscountPolicies(params: {
  planId?: string;
  rateType?: RateType;
  page?: number;
  pageSize?: number;
  returnRelatedPlans?: boolean;
}) {
  const [discounts, setDiscounts] = useState<RateDiscountPolicy[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);

  const [ratesClient] = useClientsStore((store) => [store.ratesClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await ratesClient.listRateDiscountPolicies({
          planId: params.planId,
          type: params.rateType,
          page: params.page,
          pageSize: params.pageSize,
          returnRelatedPlans: params.returnRelatedPlans,
        });

        setDiscounts(data);

        if (data.length === params.pageSize) {
          setPageCount((params.page || 0) + 1);
        }
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    ratesClient,
    params.planId,
    params.rateType,
    params.page,
    params.pageSize,
    params.returnRelatedPlans,
  ]);

  return { discounts, setDiscounts, isLoading, pageCount };
}
