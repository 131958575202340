import { useState, useEffect } from "react";
import {
  OrderByOrder,
  Plan,
  PlansOrderBy,
  PlanVisibility,
} from "@omi-lab/cresus-typescript";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";

export function useListPlans(params: {
  nameIncludes?: string;
  page?: number;
  pageSize?: number;
}) {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);

  const [plansClient] = useClientsStore((store) => [store.plansClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await plansClient.listPlans({
          visibility: [PlanVisibility.Public, PlanVisibility.Hidden],
          orderBy: PlansOrderBy.CreatedAt,
          order: OrderByOrder.Desc,
          nameIncludes: params.nameIncludes,
          page: params.page,
          pageSize: params.pageSize,
          returnRelatedFeatures: true,
          returnRelatedPlanQuotas: true,
          returnRelatedRates: true,
        });

        setPlans(data);

        if (data.length === params.pageSize) {
          setPageCount((params.page || 0) + 1);
        }
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [plansClient, params.nameIncludes, params.page, params.pageSize]);

  return { plans, setPlans, isLoading, pageCount };
}
