import {
  Table,
  Modal,
  Col,
  Checkbox,
  Select,
  Row,
  Pagination,
  Input,
  Form,
} from "antd";
import { FC, useEffect, useState } from "react";
import {
  OrderByOrder,
  Plan,
  PlanFeatureName,
  PlanRecurrenceTier,
  PlansOrderBy,
  PlanTier,
  PlanVisibility,
  UpdatePlanBody,
} from "@omi-lab/cresus-typescript";

import { useClientsStore } from "../../../store/clients";

import { useListPlans } from "../hooks";

interface Props {}

export const Plans: FC<Props> = () => {
  const [nameIncludes, setNameIncludes] = useState<string>();
  const [isTrial, setIsTrial] = useState<boolean>();
  const [visibility, setVisibility] = useState<PlanVisibility[]>([
    PlanVisibility.Public,
    PlanVisibility.Hidden,
  ]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [order, setOrder] = useState<OrderByOrder | undefined>(
    OrderByOrder.Desc,
  );
  const [orderBy, setOrderBy] = useState<PlansOrderBy | undefined>(
    PlansOrderBy.CreatedAt,
  );
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const plansClient = useClientsStore((store) => store.plansClient);

  useEffect(() => {
    setPage(1);
  }, [visibility, isTrial]);

  const { plans, setPlans, pageCount, isLoading } = useListPlans({
    nameIncludes,
    visibility,
    isTrial,
    order,
    orderBy,
    page,
    pageSize,
    returnRelatedFeatures: true,
    returnRelatedPlanQuotas: true,
    returnRelatedRates: true,
  });

  const updatePlan = async (id: string, body: UpdatePlanBody) => {
    try {
      setIsUpdating(true);
      await plansClient
        .updatePlan({
          planId: id,
          body,
          returnRelatedFeatures: true,
          returnRelatedPlanQuotas: true,
          returnRelatedRates: true,
        })
        .then(({ data }) =>
          setPlans(
            plans
              .map((plan) => (plan.id === id ? data : plan))
              .filter((plan) => visibility.includes(plan.visibility)),
          ),
        );
      Modal.success({
        content: "The plan was successfully updated.",
      });
    } catch (error: any) {
      Modal.error({
        content: error?.response?.data?.error || error.message,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Col style={{ padding: "15px" }}>
      <Row gutter={16} justify="start" style={{ marginBottom: "10px" }}>
        <Col span={3}>
          <Input
            placeholder="Name"
            value={nameIncludes}
            onChange={(e) => setNameIncludes(e.target.value)}
          />
        </Col>
        <Col span={3}>
          <Select
            allowClear
            showSearch
            style={{ width: "100%" }}
            placeholder="Visibility"
            mode="multiple"
            filterOption={false}
            value={visibility}
            onSelect={(v) => setVisibility([...visibility, v])}
            onDeselect={(v) => setVisibility(visibility.filter((i) => i !== v))}
            onClear={() => setVisibility([])}
          >
            {Object.values(PlanVisibility).map((v) => (
              <Select.Option value={v} key={v}>
                {v}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={3}>
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Order by"
            value={orderBy}
            onSelect={(v) => setOrderBy(v)}
            onClear={() => setOrderBy(undefined)}
          >
            {Object.values(PlansOrderBy).map((v) => (
              <Select.Option value={v} key={v}>
                {v}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={3}>
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Order"
            value={order}
            onSelect={(v) => setOrder(v)}
            onClear={() => setOrder(undefined)}
          >
            {Object.values(OrderByOrder).map((v) => (
              <Select.Option value={v} key={v}>
                {v}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={1}>
          <Form.Item label="Is trial">
            <Checkbox
              checked={isTrial}
              onChange={(e) => setIsTrial(e.target.checked)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Table
        dataSource={plans}
        pagination={false}
        loading={isLoading}
        columns={[
          {
            title: "",
            key: "index",
            dataIndex: "index",
            width: "5%",
            render: (_, __, index) => <p>{index + (page - 1) * pageSize}</p>,
          },
          {
            title: "ID",
            key: "id",
            dataIndex: "id",
            width: "10%",
            render: (_, record) => <p>{record.id}</p>,
          },
          {
            title: "Name",
            key: "name",
            dataIndex: "name",
            width: "5%",
            render: (_, record) => <p>{record.name}</p>,
          },
          {
            title: "Tier",
            key: "tier",
            width: "5%",
            render: (_, record: Plan) => (
              <Select
                value={record.tier}
                onChange={(v) =>
                  updatePlan(record.id, {
                    tier: v,
                  })
                }
                style={{ width: "100%" }}
              >
                {Object.values(PlanTier).map((tier) => (
                  <Select.Option value={tier}>{tier}</Select.Option>
                ))}
              </Select>
            ),
          },
          {
            title: "Recurrence",
            key: "recurrenceTier",
            width: "5%",
            render: (_, record: Plan) => (
              <Select
                value={record.recurrenceTier}
                onChange={(v) =>
                  updatePlan(record.id, {
                    recurrenceTier: v,
                  })
                }
                style={{ width: "100%" }}
              >
                {Object.values(PlanRecurrenceTier).map((recurrenceTier) => (
                  <Select.Option value={recurrenceTier}>
                    {recurrenceTier}
                  </Select.Option>
                ))}
              </Select>
            ),
          },
          {
            title: "Type",
            key: "type",
            dataIndex: "type",
            width: "5%",
          },
          {
            title: "Visibility",
            key: "visibility",
            width: "5%",
            render: (_, record: Plan) => (
              <Select
                value={record.visibility}
                onChange={(v) =>
                  updatePlan(record.id, {
                    visibility: v,
                  })
                }
              >
                {Object.values(PlanVisibility).map((visibility) => (
                  <Select.Option value={visibility}>{visibility}</Select.Option>
                ))}
              </Select>
            ),
          },
          {
            title: "Price",
            key: "price",
            dataIndex: "price",
            width: "5%",
            render: (_, record: Plan) => <p>{record.price}€</p>,
          },
          {
            title: "Billing interval",
            key: "billingInterval",
            width: "10%",
            render: (_, record: Plan) => (
              <p>
                Every {record.billingInterval} {record.billingIntervalUnit}
              </p>
            ),
          },
          {
            title: "Credits usage interval",
            key: "creditsUsageInterval",
            width: "10%",
            render: (_, record: Plan) => (
              <p>
                {record.maxCredits} every {record.creditsUsageInterval}{" "}
                {record.creditsUsageIntervalUnit}
              </p>
            ),
          },
          {
            title: "Extra credit",
            key: "extraCreditUnitAmountCents",
            width: "5%",
            render: (_, record: Plan) => (
              <p>
                {((record.extraCreditUnitAmountCents || 0) / 100).toFixed(2)}€
              </p>
            ),
          },
          {
            title: "Features",
            key: "features",
            width: "15%",
            render: (_, record: Plan) => (
              <Select
                disabled={isUpdating}
                mode="multiple"
                value={(record.features || []).map(({ name }) => name)}
                maxTagCount={3}
                onSelect={(name) =>
                  updatePlan(record.id, {
                    features: (record.features || [])
                      .map((feature) => feature.name)
                      .concat(name),
                  })
                }
                onDeselect={(name) =>
                  updatePlan(record.id, {
                    features: (record.features || [])
                      .filter((feature) => feature.name !== name)
                      .map((feature) => feature.name),
                  })
                }
              >
                {Object.values(PlanFeatureName).map((name) => (
                  <Select.Option value={name} key={name}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            ),
          },
          {
            title: "Cancel at period end",
            dataIndex: "",
            key: "cancelAtPeriodEnd",
            width: "5%",
            render: (_, record) => (
              <Checkbox disabled checked={record.cancelAtPeriodEnd} />
            ),
          },
          {
            title: "Report extra credits only",
            dataIndex: "",
            key: "creditsUsageReportExtraUsageOnly",
            width: "5%",
            render: (_, record) => (
              <Checkbox
                disabled
                checked={record.creditsUsageReportExtraUsageOnly}
              />
            ),
          },
          {
            title: "Is trial",
            dataIndex: "",
            key: "isTrial",
            width: "5%",
            render: (_, record) => (
              <Checkbox disabled checked={record.isTrial} />
            ),
          },
        ]}
      />
      <Pagination
        style={{ marginTop: 20 }}
        current={page}
        total={pageCount * pageSize}
        onChange={setPage}
        onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
        showSizeChanger={true}
      />
    </Col>
  );
};
