import { FC } from "react";
import { Form, Input, Col, Select, Checkbox } from "antd";
import { useFormikContext } from "formik";
import { CreatePlanBody } from "@omi-lab/cresus-typescript";

interface Props {}

export const Summary: FC<Props> = () => {
  const { values } = useFormikContext<CreatePlanBody>();

  return (
    <Col
      style={{
        backgroundColor: "#fafafa",
        border: "1px dashed #d9d9d9",
        alignItems: "center",
        padding: "30px",
      }}
    >
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
        <Form.Item label="Name" name="name">
          <Input defaultValue={values.name} disabled />
        </Form.Item>
        <Form.Item label="Type" name="type">
          <Input defaultValue={values.type} disabled />
        </Form.Item>
        <Form.Item label="Visibility" name="visibility">
          <Input defaultValue={values.visibility} disabled />
        </Form.Item>
        <Form.Item label="Is trial" name="isTrial">
          <Checkbox checked={!!values.isTrial} disabled />
        </Form.Item>
        <Form.Item label="Price" name="price">
          <Input
            type="number"
            defaultValue={values.price!}
            disabled
            suffix={values.currency}
          />
        </Form.Item>
        <Form.Item label="Billing interval" name="billingInterval">
          <Input
            defaultValue={`Billed every ${values.billingInterval} ${values.billingIntervalUnit}`}
            disabled
          />
        </Form.Item>
        <Form.Item label="Credits" name="creditsUpTo">
          <Input type="number" defaultValue={values.creditsUpTo!} disabled />
        </Form.Item>
        <Form.Item label="Credits usage interval" name="creditsUsageInterval">
          <Input
            defaultValue={`Every ${values.creditsUsageInterval} ${values.creditsUsageIntervalUnit}`}
            disabled
          />
        </Form.Item>
        <Form.Item
          label="Extra credit unit amount"
          name="extraCreditUnitAmountCents"
        >
          <Input
            defaultValue={(values.extraCreditUnitAmountCents! / 100).toFixed(2)}
            disabled
            suffix={values.currency}
          />
        </Form.Item>
        <Form.Item label="Allow for extra credits" name="allowForExtraCredits">
          <Checkbox checked={!!values.allowForExtraCredits} disabled />
        </Form.Item>
        <Form.Item label="Trial duration weeks" name="trialDurationWeeks">
          <Input defaultValue={values.trialDurationWeeks} disabled />
        </Form.Item>
        <Form.Item label="Cancel at period end" name="cancelAtPeriodEnd">
          <Checkbox checked={!!values.cancelAtPeriodEnd} disabled />
        </Form.Item>
        <Form.Item label="Features" name="features">
          <Select
            disabled
            mode="tags"
            defaultValue={values.features || []}
          ></Select>
        </Form.Item>
        <Form.Item label="Quotas" name="quotas">
          <Select
            disabled
            mode="tags"
            defaultValue={values?.quotas?.map((quota) =>
              quota.intervalUnit
                ? `${quota.maxUsage} ${quota.name} every ${quota.interval} ${quota.intervalUnit}`
                : `${quota.maxUsage} ${quota.name} at all`,
            )}
          ></Select>
        </Form.Item>
        <Form.Item label="Rates" name="rates">
          <Select
            disabled
            mode="tags"
            defaultValue={values?.rates?.map((rate) =>
              rate.duration
                ? `[${rate.type}] ${rate.resolutionName} ${rate.duration}s ${rate.rate} ${values.currency}`
                : `[${rate.type}] ${rate.rate} ${values.currency}`,
            )}
          ></Select>
        </Form.Item>
      </Form>
    </Col>
  );
};
