import { useState, useEffect } from "react";
import { OrganizationCreditsCoupon } from "@omi-lab/cresus-typescript";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";

export function useListOrganizationCreditsCoupons(organizationId?: string) {
  const [organizationCreditsCoupons, setOrganizationCreditsCoupons] = useState<
    OrganizationCreditsCoupon[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const [organizationCreditsCouponsClient] = useClientsStore((store) => [
    store.organizationCreditsCouponsClient,
  ]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (!organizationId) {
          return;
        }
        setOrganizationCreditsCoupons(
          (
            await organizationCreditsCouponsClient.listOrganizationCreditsCoupons(
              {
                organizationId,
              },
            )
          ).data,
        );
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [organizationId, organizationCreditsCouponsClient]);

  return {
    organizationCreditsCoupons,
    setOrganizationCreditsCoupons,
    isLoading,
  };
}
