import { FC, useEffect, useState } from "react";
import {
  Table,
  Modal,
  Col,
  Select,
  Row,
  Pagination,
  Input,
  Button,
  Popconfirm,
} from "antd";
import {
  CreateRateDiscountPolicyBody,
  RateType,
  UpdateRateDiscountPolicyBody,
} from "@omi-lab/cresus-typescript";

import { useClientsStore } from "../../../store/clients";

import { useListPlans, useListRateDiscountPolicies } from "../hooks";
import { CreateRateDiscountPolicyModal } from "../components/CreateRateDiscountPolicyModal";

interface Props {}

export const RateDiscountPolicies: FC<Props> = () => {
  const [planId, setPlanId] = useState<string>();
  const [rateType, setRateType] = useState<RateType>();
  const [planNameIncludes, setPlanNameIncludes] = useState<string>();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const ratesClient = useClientsStore((store) => store.ratesClient);

  useEffect(() => {
    setPage(1);
  }, [planId, rateType]);

  const { discounts, setDiscounts, pageCount, isLoading } =
    useListRateDiscountPolicies({
      planId,
      rateType,
      page,
      pageSize,
      returnRelatedPlans: true,
    });
  const { plans } = useListPlans({
    nameIncludes: planNameIncludes,
    page: 1,
    pageSize: 20,
  });

  const createRateDiscountPolicy = async (
    body: CreateRateDiscountPolicyBody,
  ) => {
    try {
      setIsUpdating(true);
      await ratesClient
        .createRateDiscountPolicy({
          body,
          returnRelatedPlans: true,
        })
        .then(({ data }) => setDiscounts([...discounts, data]));
      Modal.success({
        content: "The discount policy was successfully created.",
      });
    } catch (error: any) {
      Modal.error({
        content: error?.response?.data?.error || error.message,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const deleteRateDiscountPolicy = async (id: string) => {
    try {
      setIsUpdating(true);
      await ratesClient
        .deleteRateDiscountPolicy({
          rateDiscountPolicyId: id,
        })
        .then(() =>
          setDiscounts(discounts.filter((discount) => discount.id !== id)),
        );
      Modal.success({
        content: "The discount policy was successfully deleted.",
      });
    } catch (error: any) {
      Modal.error({
        content: error?.response?.data?.error || error.message,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const updateRateDiscountPolicy = async (
    id: string,
    body: UpdateRateDiscountPolicyBody,
  ) => {
    try {
      setIsUpdating(true);
      await ratesClient
        .updateRateDiscountPolicy({
          rateDiscountPolicyId: id,
          body,
          returnRelatedPlans: true,
        })
        .then(({ data }) =>
          setDiscounts(
            discounts.map((discount) => (discount.id === id ? data : discount)),
          ),
        );
      Modal.success({
        content: "The discount policy was successfully updated.",
      });
    } catch (error: any) {
      Modal.error({
        content: error?.response?.data?.error || error.message,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Col style={{ padding: "15px" }}>
      <Row gutter={16} justify="start" style={{ marginBottom: "10px" }}>
        <Col span={3}>
          <Select
            allowClear
            showSearch
            style={{ width: "100%" }}
            placeholder="Plan"
            filterOption={false}
            value={planId}
            onSearch={setPlanNameIncludes}
            onSelect={setPlanId}
            onClear={() => setPlanId(undefined)}
          >
            {plans.map((plan) => (
              <Select.Option value={plan.id} key={plan.id}>
                {plan.name} ({plan.id})
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={3}>
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Rate type"
            value={rateType}
            onSelect={setRateType}
            onClear={() => setRateType(undefined)}
          >
            {Object.values(RateType).map((v) => (
              <Select.Option value={v} key={v}>
                {v}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Table
        dataSource={discounts}
        pagination={false}
        loading={isLoading || isUpdating}
        columns={[
          {
            title: "ID",
            key: "id",
            dataIndex: "id",
            width: "10%",
          },
          {
            title: "Policy type",
            key: "policyType",
            dataIndex: "policyType",
            width: "10%",
          },
          {
            title: "Rate type",
            key: "rateType",
            dataIndex: "rateType",
            width: "10%",
          },
          {
            title: "Min units",
            key: "minUnits",
            width: "15%",
            render: (_, record) => (
              <Input
                disabled
                name="minUnits"
                type="number"
                value={record.minUnits || 0}
                onChange={(e) =>
                  updateRateDiscountPolicy(record.id, {
                    minUnits: Number.parseInt(e.target.value),
                  })
                }
              />
            ),
          },
          {
            title: "Discount %",
            key: "discountPercents",
            width: "15%",
            render: (_, record) => (
              <Input
                disabled
                name="discountPercents"
                type="number"
                value={record.discountPercents || 0}
                onChange={(e) =>
                  updateRateDiscountPolicy(record.id, {
                    discountPercents: Number.parseInt(e.target.value),
                  })
                }
              />
            ),
          },
          {
            title: "Plans",
            key: "plans",
            width: "30%",
            render: (_, record) => (
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Plans"
                filterOption={false}
                mode="multiple"
                maxTagCount={1}
                value={record.plans?.map(({ id }) => id)}
                searchValue={planNameIncludes}
                onSearch={setPlanNameIncludes}
                onSelect={(pid) =>
                  updateRateDiscountPolicy(record.id, {
                    planIds: [
                      ...(record.plans?.map(({ id }) => id) || []),
                      pid,
                    ],
                  })
                }
                onDeselect={(pid) =>
                  updateRateDiscountPolicy(record.id, {
                    planIds: (
                      record.plans?.filter(({ id }) => id !== pid) || []
                    ).map(({ id }) => id),
                  })
                }
              >
                {plans.map((plan) => (
                  <Select.Option value={plan.id} key={plan.id}>
                    {plan.name} ({plan.id})
                  </Select.Option>
                ))}
              </Select>
            ),
          },
          {
            title: "Actions",
            key: "actions",
            width: "10%",
            render: (_, record) => (
              <Row>
                <Popconfirm
                  title="Are you sure to delete this discount policy ?"
                  onConfirm={() => deleteRateDiscountPolicy(record.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary" danger>
                    Delete
                  </Button>
                </Popconfirm>
              </Row>
            ),
          },
        ]}
      />
      <Pagination
        style={{ marginTop: 20 }}
        current={page}
        total={pageCount * pageSize}
        onChange={setPage}
        onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
        showSizeChanger={true}
      />
      <Row justify="end">
        <Button type="primary" onClick={() => setIsModalOpen(true)}>
          Create a new policy
        </Button>
      </Row>
      <CreateRateDiscountPolicyModal
        createRateDiscountPolicy={createRateDiscountPolicy}
        isVisible={isModalOpen}
        setIsVisible={setIsModalOpen}
      />
    </Col>
  );
};
