import { FC } from "react";
import { Form, Input, Col, Select, Checkbox } from "antd";
import { useFormikContext } from "formik";
import {
  CreatePlanBody,
  IntervalUnit,
  PlanVisibility,
  PlanFeatureName,
  PlanType,
  PlanTier,
  PlanRecurrenceTier,
} from "@omi-lab/cresus-typescript";

interface Props {}

export const Details: FC<Props> = () => {
  const {
    values,
    handleChange,
    setValues,
    errors,
    touched,
    handleBlur,
    setTouched,
  } = useFormikContext<CreatePlanBody>();

  return (
    <Col
      style={{
        backgroundColor: "#fafafa",
        border: "1px dashed #d9d9d9",
        alignItems: "center",
        padding: "30px",
      }}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        initialValues={values}
      >
        <Form.Item
          label="Name"
          name="name"
          help={touched.name && errors.name}
          validateStatus={touched.name && errors.name ? "error" : "success"}
        >
          <Input
            defaultValue={values.name}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Item>
        <Form.Item
          label="Tier"
          name="tier"
          help={touched.tier && errors.tier}
          validateStatus={touched.tier && errors.tier ? "error" : "success"}
        >
          <Select
            value={values.tier}
            defaultValue={values.tier}
            onChange={(value) =>
              setValues({
                ...values,
                tier: value,
              })
            }
            onBlur={() =>
              setTouched({
                ...touched,
                tier: true,
              })
            }
          >
            {Object.values(PlanTier).map((value) => (
              <Select.Option value={value}>{value}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Recurrence tier"
          name="recurrenceTier"
          help={touched.recurrenceTier && errors.recurrenceTier}
          validateStatus={
            touched.recurrenceTier && errors.recurrenceTier
              ? "error"
              : "success"
          }
        >
          <Select
            value={values.recurrenceTier}
            defaultValue={values.recurrenceTier}
            onChange={(value) =>
              setValues({
                ...values,
                recurrenceTier: value,
              })
            }
            onBlur={() =>
              setTouched({
                ...touched,
                recurrenceTier: true,
              })
            }
          >
            {Object.values(PlanRecurrenceTier).map((value) => (
              <Select.Option value={value}>{value}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          help={touched.type && errors.type}
          validateStatus={touched.type && errors.type ? "error" : "success"}
        >
          <Select
            value={values.type}
            defaultValue={values.type}
            onChange={(value) =>
              setValues({
                ...values,
                type: value,
              })
            }
            onBlur={() =>
              setTouched({
                ...touched,
                type: true,
              })
            }
          >
            {Object.values(PlanType).map((value) => (
              <Select.Option value={value}>{value}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Visibility"
          name="visibility"
          help={touched.visibility && errors.visibility}
          validateStatus={
            touched.visibility && errors.visibility ? "error" : "success"
          }
        >
          <Select
            value={values.visibility}
            defaultValue={values.visibility}
            onChange={(value) =>
              setValues({
                ...values,
                visibility: value,
              })
            }
            onBlur={() =>
              setTouched({
                ...touched,
                visibility: true,
              })
            }
          >
            {Object.values(PlanVisibility).map((value) => (
              <Select.Option value={value}>{value}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Is trial" name="isTrial">
          <Checkbox
            defaultChecked={values.isTrial!}
            checked={values.isTrial!}
            value={values.isTrial!}
            onChange={(e) =>
              setValues((values) => ({
                ...values,
                isTrial: e.target.checked,
              }))
            }
          />
        </Form.Item>
        <Form.Item
          label="Price"
          name="price"
          help={touched.price && errors.price}
          validateStatus={touched.price && errors.price ? "error" : "success"}
        >
          <Input
            type="number"
            defaultValue={values.price || 0}
            value={values.price || 0}
            onChange={handleChange}
            onBlur={handleBlur}
            suffix="€"
          />
        </Form.Item>
        <Form.Item
          label="Billing interval unit"
          name="billingIntervalUnit"
          help={touched.billingIntervalUnit && errors.billingIntervalUnit}
          validateStatus={
            touched.billingIntervalUnit && errors.billingIntervalUnit
              ? "error"
              : "success"
          }
        >
          <Select
            value={values.billingIntervalUnit}
            defaultValue={values.billingIntervalUnit}
            onChange={(value) =>
              setValues({
                ...values,
                billingIntervalUnit: value,
              })
            }
            onBlur={() =>
              setTouched({
                ...touched,
                billingIntervalUnit: true,
              })
            }
          >
            {Object.values(IntervalUnit).map((value) => (
              <Select.Option value={value}>{value}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Billing interval"
          name="billingInterval"
          help={touched.billingInterval && errors.billingInterval}
          validateStatus={
            touched.billingInterval && errors.billingInterval
              ? "error"
              : "success"
          }
        >
          <Input
            type="number"
            defaultValue={values.billingInterval}
            value={values.billingInterval}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Item>
        <Form.Item
          label="Credits"
          name="creditsUpTo"
          help={touched.creditsUpTo && errors.creditsUpTo}
          validateStatus={
            touched.creditsUpTo && errors.creditsUpTo ? "error" : "success"
          }
        >
          <Input
            type="number"
            defaultValue={values.creditsUpTo || 0}
            value={values.creditsUpTo || 0}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={values.type === PlanType.Simple}
          />
        </Form.Item>
        <Form.Item
          label="Credits usage interval unit"
          name="creditsUsageIntervalUnit"
          help={
            touched.creditsUsageIntervalUnit && errors.creditsUsageIntervalUnit
          }
          validateStatus={
            touched.creditsUsageIntervalUnit && errors.creditsUsageIntervalUnit
              ? "error"
              : "success"
          }
        >
          <Select
            value={values.creditsUsageIntervalUnit}
            defaultValue={values.creditsUsageIntervalUnit}
            onChange={(value) =>
              setValues({
                ...values,
                creditsUsageIntervalUnit: value,
              })
            }
            onBlur={() =>
              setTouched({
                ...touched,
                creditsUsageIntervalUnit: true,
              })
            }
          >
            {Object.values(IntervalUnit).map((value) => (
              <Select.Option value={value}>{value}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Credits usage interval"
          name="creditsUsageInterval"
          help={touched.creditsUsageInterval && errors.creditsUsageInterval}
          validateStatus={
            touched.creditsUsageInterval && errors.creditsUsageInterval
              ? "error"
              : "success"
          }
        >
          <Input
            type="number"
            defaultValue={values.creditsUsageInterval}
            value={values.creditsUsageInterval}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Item>
        <Form.Item
          label="Extra credit unit amount cents"
          name="extraCreditUnitAmountCents"
          help={
            touched.extraCreditUnitAmountCents &&
            errors.extraCreditUnitAmountCents
          }
          validateStatus={
            touched.extraCreditUnitAmountCents &&
            errors.extraCreditUnitAmountCents
              ? "error"
              : "success"
          }
        >
          <Input
            type="number"
            defaultValue={values.extraCreditUnitAmountCents}
            value={values.extraCreditUnitAmountCents}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={values.type === PlanType.Simple}
            suffix="¢"
          />
        </Form.Item>
        <Form.Item label="Allow for extra credits" name="allowForExtraCredits">
          <Checkbox
            defaultChecked={values.allowForExtraCredits!}
            checked={values.allowForExtraCredits!}
            value={values.allowForExtraCredits!}
            onChange={(e) =>
              setValues((values) => ({
                ...values,
                allowForExtraCredits: e.target.checked,
              }))
            }
          />
        </Form.Item>
        <Form.Item
          label="Trial duration (weeks)"
          name="trialDurationWeeks"
          help={touched.trialDurationWeeks && errors.trialDurationWeeks}
          validateStatus={
            touched.trialDurationWeeks && errors.trialDurationWeeks
              ? "error"
              : "success"
          }
        >
          <Input
            type="number"
            defaultValue={values.trialDurationWeeks}
            value={values.trialDurationWeeks}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Item>
        <Form.Item label="Cancel at period end" name="cancelAtPeriodEnd">
          <Checkbox
            defaultChecked={values.cancelAtPeriodEnd!}
            checked={values.cancelAtPeriodEnd!}
            value={values.cancelAtPeriodEnd!}
            onChange={(e) =>
              setValues((values) => ({
                ...values,
                cancelAtPeriodEnd: e.target.checked,
              }))
            }
          />
        </Form.Item>
        <Form.Item
          label="Features"
          name="features"
          help={touched.features && errors.features}
          validateStatus={
            touched.features && errors.features ? "error" : "success"
          }
        >
          <Select
            mode="tags"
            onSelect={(name: PlanFeatureName) =>
              setValues({
                ...values,
                features: [...(values.features || []), name],
              })
            }
            onDeselect={(name: PlanFeatureName) => {
              setValues({
                ...values,
                features: (values.features || []).filter(
                  (feature: string) => feature !== name,
                ),
              });
            }}
            onBlur={handleBlur}
          >
            {Object.values(PlanFeatureName).map((feature) => (
              <Select.Option key={feature} value={feature}>
                {feature}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Col>
  );
};
